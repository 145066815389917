import React from 'react';

import Layout from '../../../components/layout';
import Container from '../../../components/container';
import ResidenceDetails from '../../../components/residence-details';

import Photos1 from '../../../images/work/15_PB_SURI/PHOTOS/SU_01.jpg';
import Photos2 from '../../../images/work/15_PB_SURI/PHOTOS/SU_02.jpg';
import Photos3 from '../../../images/work/15_PB_SURI/PHOTOS/SU_03.jpg';
import Photos4 from '../../../images/work/15_PB_SURI/PHOTOS/SU_04.jpg';
import Photos5 from '../../../images/work/15_PB_SURI/PHOTOS/SU_05.jpg';
import Photos6 from '../../../images/work/15_PB_SURI/PHOTOS/SU_06.jpg';
import Photos7 from '../../../images/work/15_PB_SURI/PHOTOS/SU_07.jpg';
import Photos8 from '../../../images/work/15_PB_SURI/PHOTOS/SU_08.jpg';
import Photos1s from '../../../images/work/15_PB_SURI/PHOTOS/450x280/SU-SM_01.jpg';
import Photos2s from '../../../images/work/15_PB_SURI/PHOTOS/450x280/SU-SM_02.jpg';
import Photos3s from '../../../images/work/15_PB_SURI/PHOTOS/450x280/SU-SM_03.jpg';
import Photos4s from '../../../images/work/15_PB_SURI/PHOTOS/450x280/SU-SM_04.jpg';
import Photos5s from '../../../images/work/15_PB_SURI/PHOTOS/450x280/SU-SM_05.jpg';
import Photos6s from '../../../images/work/15_PB_SURI/PHOTOS/450x280/SU-SM_06.jpg';
import Photos7s from '../../../images/work/15_PB_SURI/PHOTOS/450x280/SU-SM_07.jpg';
import Photos8s from '../../../images/work/15_PB_SURI/PHOTOS/450x280/SU-SM_08.jpg';

import Process1 from '../../../images/work/15_PB_SURI/PROCESS/SUR_01.jpg';
import Process2 from '../../../images/work/15_PB_SURI/PROCESS/SUR_02.jpg';
import Process3 from '../../../images/work/15_PB_SURI/PROCESS/SUR_03.jpg';
import Process4 from '../../../images/work/15_PB_SURI/PROCESS/SUR_04.jpg';
import Process5 from '../../../images/work/15_PB_SURI/PROCESS/SUR_05.jpg';
import Process6 from '../../../images/work/15_PB_SURI/PROCESS/SUR_06.jpg';
import Process1s from '../../../images/work/15_PB_SURI/PROCESS/450x280/SUR-SM_01.jpg';
import Process2s from '../../../images/work/15_PB_SURI/PROCESS/450x280/SUR-SM_02.jpg';
import Process3s from '../../../images/work/15_PB_SURI/PROCESS/450x280/SUR-SM_03.jpg';
import Process4s from '../../../images/work/15_PB_SURI/PROCESS/450x280/SUR-SM_04.jpg';
import Process5s from '../../../images/work/15_PB_SURI/PROCESS/450x280/SUR-SM_05.jpg';
import Process6s from '../../../images/work/15_PB_SURI/PROCESS/450x280/SUR-SM_06.jpg';

const Residence03 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="SPANISH ECLECTIC"
        residenceLocation="PEBBLE BEACH, CALIFORNIA"
        infoList={[
          'Remodel and addition to existing 1,760 SF single family residence. Phase 1 - 370 SF master bedroom addition. Phase 2 - 1,600 SF addition, including upper level office and bedroom suite with rooftop observation deck to a total of 3,300 SF.',
          'Design - 2018',
          'Construction - 2019-2020',
          'General Contractor: Icon Construction, Inc',
          'Structural Engineer: Structures Engineering, Inc',
        ]}
        testimonialList={[]}
        photos={[
          Photos1,
          Photos2,
          Photos3,
          Photos4,
          Photos5,
          Photos6,
          Photos7,
          Photos8,
        ]}
        photosSmall={[
          Photos1s,
          Photos2s,
          Photos3s,
          Photos4s,
          Photos5s,
          Photos6s,
          Photos7s,
          Photos8s,
        ]}
        processPhotos={[
          Process1,
          Process2,
          Process3,
          Process4,
          Process5,
          Process6,
        ]}
        processPhotosSmall={[
          Process1s,
          Process2s,
          Process3s,
          Process4s,
          Process5s,
          Process6s,
        ]}
      />
    </Container>
  </Layout>
);

export default Residence03;
